globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"v1.7.10"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';
import { getSampleRate } from 'sentry.helpers';

const config: Sentry.BrowserOptions = {
  dsn: 'https://b83c872be5e73227b80d9231e110ff1f@o4506080610353152.ingest.us.sentry.io/4506096634167296',

  environment: process.env.SENTRY_ENVIRONMENT,

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: getSampleRate(0.02),
  profilesSampleRate: 1.0, // Profiling sample rate is relative to tracesSampleRate

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: process.env.DEBUG_SENTRY === '1',

  // Only enable Sentry on CloudRun
  enabled: process.env.STANDALONE === 'true',

  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
  integrations: [],

  beforeSend(event) {
    // Filter out hard navigate errors from NextJS
    // https://sentry.io/answers/handle-hard-navigation-errors-in-nextjs/
    if (event.exception?.values?.[0].value?.includes('Invariant: attempted to hard navigate to the same URL')) {
      return null;
    }

    return event;
  },

  // See https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/#decluttering-sentry
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Ignore fetch errors, most of them happen due to slow network
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'Network Error',
    // Facebook iOS autofill not properly implemented
    '_AutofillCallbackHandler',
    // Hubspot tracking
    '_hsq',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    // Ignore Google flakiness
    /\/(gtm|ga|analytics)\.js/i,
    // Ignore LaunchDarkly network errors
    /launchdarkly/i,
    // Ignore LiveChat network errors
    /\/tracking\.js/i,
    // Ignore Google One-tap errors
    /gsi\/client/i,
    // Ignore Google tag manager errors
    /gtag\/js/i,
    // Ignore Recaptcha errors
    /recaptcha__en/i,
    // Ignore Userberry errors
    /useberryScript/i,
    // Ignore maze.co errors
    /contextualScaffold/i,
    // Ignore bing errors
    /bat\.bing\.com/i,
  ],
};

Sentry.init(config);
